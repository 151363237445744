import { Navigate, Outlet, useLocation } from "react-router-dom";
import { userRoles } from "../utils/userRoles";
import { useAuthStore } from "../utils/useStore";
import { useMemo, useState } from "react";

const ProtectedRoute = ({ roles = [...Object.values(userRoles)] }) => {
  const location = useLocation();
  const { user, setUser } = useAuthStore();
  const isAuthorized = roles.includes(user?.role);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useMemo(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch("/api/check-auth", {
          method: "GET",
          // headers: new Headers({ Authorization: "Bearer " + JSON.parse(localStorage.getItem("currentUser")).token, "Content-Type": "application/json" }),
        });
        const data = await response.json();
        if (response.ok) setAuthenticated(data.message === "Authenticated.");
        setLoading(false);
      } catch (err) {
        console.log("Error check-auth:", err);
        setUser(null);
        setAuthenticated(false);
        localStorage.removeItem("currentUser");
        setLoading(false);
      }
    };
    checkAuth();
  }, [location.pathname]);

  if (isLoading) return null;

  if ((!isAuthenticated || !isAuthorized || !user) && !isLoading) {
    localStorage.setItem("prevLocation", `${location.pathname}${location.search}`);
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
