import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import FrameExample from "./Frame.jsx";
import * as Sentry from "@sentry/react";
import { extraErrorDataIntegration, httpClientIntegration } from "@sentry/integrations";
import Login from "./pages/login/index.jsx";
import HomePage from "./pages/home/index.jsx";
import ProductsPage from "./pages/products/index.jsx";
import ProductPage from "./pages/product/index.jsx";
import PurchasesPage from "./pages/purchases/index.jsx";
import PurchasePage from "./pages/purchase/index.jsx";
import ProductionPage from "./pages/production/index.jsx";
import QcPage from "./pages/QC/index.jsx";
import QCDetail from "./pages/QCPage/index.jsx";
import SerialsPage from "./pages/serials/index.jsx";
import BoxPage from "./pages/box/index.jsx";
import PitchersPage from "./pages/pitchers/index.jsx";
import BoxesPage from "./pages/boxes/index.jsx";
import FulfilmentPage from "./pages/fulfilment/index.jsx";
import SalesPage from "./pages/sales/index.jsx";
import SalePage from "./pages/sale/index.jsx";
import ProductDesigner from "./pages/productDesigner/index.tsx";
import CompaniesPage from "./pages/companies/index.jsx";
import CompanyPage from "./pages/company/index.jsx";
import ProtectedRoute from "./shared/protectedRoute.jsx";
import StockAdjusterPage from "./pages/stock/index.jsx";
import TranslationsPage from "./pages/translations/index.jsx";
import RfidEventsPage from "./pages/rfid/index.jsx";
import SerialPage from "./pages/serial/index.jsx";
import SettingsPage from "./pages/settings/index.jsx";
import WorkOrdersPage from "./pages/workorders/index.jsx";
import WorkOrderPage from "./pages/workorder/index.jsx";
import { WorkstationPage } from "./pages/workstation/index.jsx";
import CheckOverviewPage from "./pages/checks/index.jsx";
import CheckListPage from "./pages/checklist/index.jsx";
import CheckPage from "./pages/check/index.jsx";
import { useAuthStore, useLookupStore, useStationStore } from "./utils/useStore.js";
import ChangelogPage from "./pages/changelog/index.jsx";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://62fd03bc342f45b7b68bcbc67d6ead87@o534347.ingest.sentry.io/5663269",
    debug: true,
    release: "latest-release",
    environment: process.env.NODE_ENV,
    sampleRate: 1.0,
    replaysOnErrorSampleRate: 0,
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    maxBreadcrumbs: 50,
    sendDefaultPii: true,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({ useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes }),
      // Sentry.replayIntegration({ blockAllMedia: false, maskAllText: false, maskInputOptions: { password: true } }),
      extraErrorDataIntegration({ depth: 4 }),
      httpClientIntegration(),
    ],
  });
}

export function App() {
  const { user, setUser, isLoading } = useAuthStore();
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("currentUser")) {
      const preservedUser = JSON.parse(localStorage.getItem("currentUser"));
      setUser(preservedUser);
      setInitiallyLoaded(true);
    }
  }, []);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const userSaml = params.get('userSaml') ? JSON.parse(decodeURIComponent(params.get('userSaml'))) : null;

  useEffect(() => {
    if (userSaml && !user) {
      localStorage.setItem('currentUser', JSON.stringify(userSaml.user));
      setUser(userSaml.user);
      setInitiallyLoaded(true);
    }
  }, []);


  const setHardwaretypes = useLookupStore((state) => state.setHardwaretypes);
  const setHardwareboilertypes = useLookupStore((state) => state.setHardwareboilertypes);
  const setPickingplaces = useLookupStore((state) => state.setPickingplaces);
  const setProducttypes = useLookupStore((state) => state.setProducttypes);
  const setRecipetypes = useLookupStore((state) => state.setRecipetypes);
  const setSoftwaretypes = useLookupStore((state) => state.setSoftwaretypes);
  const setUnits = useLookupStore((state) => state.setUnits);
  const setUsers = useLookupStore((state) => state.setUsers);
  const setWorkstations = useLookupStore((state) => state.setWorkstations);
  const setStationData = useStationStore((state) => state.setStationData);

  if (initiallyLoaded && user && !isLoading) {
    setHardwaretypes();
    setHardwareboilertypes();
    setPickingplaces();
    setProducttypes();
    setRecipetypes();
    setSoftwaretypes();
    setUnits();
    setUsers();
    setWorkstations();
    setStationData();
  } else if (initiallyLoaded && !user && !isLoading) {
    window.history.pushState({ pathname: "/login" }, "login", "/login");
  }

  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  const router = sentryCreateBrowserRouter([
    { path: "/login", element: <Login /> },
    {
      element: <FrameExample />,
      children: [
        {
          element: <ProtectedRoute />,
          children: [
            { path: "/", element: <HomePage /> },
            { path: "/home", element: <HomePage /> },
            { path: "/products", element: <ProductsPage /> },
            { path: "/products/:id", element: <ProductPage /> },
            { path: "/purchases", element: <PurchasesPage /> },
            { path: "/purchases/:id", element: <PurchasePage /> },
            { path: "/production", element: <ProductionPage /> },
            { path: "/workorders", element: <WorkOrdersPage /> },
            { path: "/workorders/:id", element: <WorkOrderPage /> },
            { path: "/qc", element: <QcPage /> },
            { path: "/qc/:id", element: <QCDetail /> },
            { path: "/serials", element: <SerialsPage /> },
            { path: "/serials/:id", element: <SerialPage /> },
            { path: "/changelog", element: <ChangelogPage /> },
            { path: "/checkoverview", element: <CheckOverviewPage /> },
            { path: "/checkoverview/checklists", element: <CheckOverviewPage /> },
            { path: "/checkoverview/checks", element: <CheckOverviewPage /> },
            { path: "/checklists/:id", element: <CheckListPage /> },
            { path: "/checks/:id", element: <CheckPage /> },
            { path: "/box", element: <BoxPage /> },
            { path: "/pitchers", element: <PitchersPage /> },
            { path: "/boxes", element: <BoxesPage /> },
            { path: "/fulfilment", element: <FulfilmentPage /> },
            { path: "/sales", element: <SalesPage /> },
            { path: "/sales/:id", element: <SalePage /> },
            { path: "/stock", element: <StockAdjusterPage /> },
            { path: "/designer", element: <ProductDesigner /> },
            { path: "/designer/greg", element: <ProductDesigner /> },
            { path: "/designer/jack", element: <ProductDesigner /> },
            { path: "/translations", element: <TranslationsPage /> },
            { path: "/companies", element: <CompaniesPage /> },
            { path: "/companies/:id", element: <CompanyPage /> },
            { path: "/rfid", element: <RfidEventsPage /> },
            { path: "/settings", element: <SettingsPage /> },
            { path: "/workstations/:id", element: <WorkstationPage /> },
            { path: "*", element: <HomePage /> },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);
