export default function Login() {
  return <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundImage: 'url(schuilenburg.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }}>
    <div style={{
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      padding: '2rem',
      borderRadius: '5px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '30%'
    }}>
      <img
        src="https://cdn.prod.website-files.com/62706052b234ba15621a939a/630829301b0b52661a57b6b0_logoperfectmoosehorizontalblackontransaparant_1.png"
        alt="perfectmoose"
        style={{
          maxWidth: '50%',
          height: 'auto',
          marginBottom: '1rem'
        }}
      />
      <button
        onClick={() => window.location.href = '/api/saml/login'}
        style={{
          marginTop: '1rem',
          padding: '0.5rem 1rem',
          fontSize: '1rem',
          backgroundColor: 'lightgray',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          fontWeight: 'bold'
        }}
      >
        LOGIN WITH MICROSOFT ACCOUNT
      </button>
    </div>
  </div>
}