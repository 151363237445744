import { alphabetize } from "../functions";

export const initialSerial = {
  serialnumber: null,
  qualityCheckDate: new Date().toISOString().slice(0, 10),
  qualityCheckByUserID: null,
  remarks: "",
  hardwareTypeID: 2,
  hardwareBoilerTypeID: null,
  Status: "created",
};

export const initialLabel = {
  ManufactureYear: new Date().getFullYear(),
  Location: "Start",
  Type: "Print",
  Timestamp: Math.floor(Date.now() / 1000),
};

export const statusOptions = [
  { label: "Created", value: "created" },
  { label: "Milk tested", value: "milk_tested" },
  { label: "Bench tested", value: "bench_tested" },
  { label: "Fulfilled", value: "fulfilled" },
];

export const modelOptions = [
  { label: "PMG10", value: "PMG10" },
  { label: "PMG20", value: "PMG20" },
  { label: "PMJ20", value: "PMJ20" },
  { label: "PMJ21", value: "PMJ21" },
  { label: "PMJ30", value: "PMJ30" },
  { label: "PMJ31", value: "PMJ31" },
];

export const cableOptions = [
  { label: "L - Loose (+ plug)", value: "L" },
  { label: "F - Fixed (+ plug)", value: "F" },
  { label: "X - Fixed (no plug)", value: "X" },
  { label: "Y - Fixed (no plug) US/JP", value: "Y" },
];

export const colorOptions = [
  { label: "01 Black", value: "01" },
  { label: "02 White", value: "02" },
];

export const variantOptions = [
  { label: "01 Standard", value: "1" },
  { label: "02 Epic", value: "2" },
];

export const modelInfo = {
  PMG10: { RfidModelId: 1, TechnicalInfo: "100-127V 50/60Hz 30W" },
  PMG20: { RfidModelId: 2, TechnicalInfo: "208-240V 50/60Hz 50W" },
  PMJ20: { RfidModelId: 4, TechnicalInfo: "220-240V 50/60Hz 2430W" },
  PMJ21: { RfidModelId: 4, TechnicalInfo: "220-240V 50/60Hz 2980W" },
  PMJ30: { RfidModelId: 4, TechnicalInfo: "200-208V 50/60Hz 2430W" },
  PMJ31: { RfidModelId: 4, TechnicalInfo: "200-208V 50/60Hz 2980W" },
};

export const getCodeString = (note) => {
  if (!note) return null;
  if (!note.includes("***")) return "NO_STRING_FOUND";
  return note.substring(note.indexOf("***"), note.indexOf("***") + 18);
};

export const saveStringInRemark = (modelString, note) => {
  if (!note) note = "";
  if (!modelString || Object.keys(modelString).length === 0) return note

  try {
    const { model, cable, letter, color, variant } = modelString;
    const newString = `***${model.value}-${cable.value}${letter.value}${color.value}-${variant.value}***`;
    if (note.includes("***")) {
      const prevCodeString = getCodeString(note);
      return note.replace(prevCodeString, newString);
    } else {
      return note.concat(` ${newString}`);
    }
  } catch (err) {
    console.log("Error saving string in serial.remark", err);
    return null;
  }
};

export const getOptions = (types, value, label) => {
  return types
    .map((t) => {
      return { value: t[value].toString(), label: t[label] };
    })
    .sort((a, b) => alphabetize(a, b, "label"));
};
