import { useState, useEffect, useCallback } from "react";
import { Badge, DataTable, Link, Card, Filters, Checkbox, Box, InlineStack } from "@shopify/polaris";
import QuickViewSalesOrderComponent from "./quickViewComponent";
import { addFilters, applyFilters, filtersToApiUrl, handleFilterChange, limitOptions, removeAllFilters, useUrl } from "../../../shared/filters/filterfunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest, getTableFooter, handleSaveOnEnter, truncate } from "../../../shared/components/functions";
import { PageSpinner } from "../../../shared/components/spinner/PageSpinner";
import ConfirmDeliveryTerm from "../confirmDeliveryTerm";

export default function FulfilmentOrders() {
  const url = useUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [showFulfilledOrders, setShowFulfilledOrders] = useState(false);
  const [filters, setFilters] = useState(
    new Map([
      ["limit", { type: "limit", name: "Limit", value: url.get("limit") || "100", options: limitOptions }],
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["q", { type: "text", name: "Q", value: url.get("q") || "" }],
      ["customer", { type: "text", name: "Customer", value: url.get("customer") || "" }],
      ["reference", { type: "text", name: "Reference", value: url.get("reference") || "" }],
    ])
  );

  useEffect(() => {
    fetchItems();
  }, [filters, showFulfilledOrders]);

  const fetchItems = useCallback(async () => {
    let filterString = filtersToApiUrl(filters);
    const data = await getRequest(`/api/fulfilment?${filterString}&showFulfilledOrders=${showFulfilledOrders}`);
    setItems(data);
    if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
    setLoaded(true);
  }, [filters, showFulfilledOrders]);

  const fulfilment_status = (toFulfill, totalItems) => {
    if (toFulfill === 0) return <Badge progress="complete">Fulfilled</Badge>;
    else if (toFulfill === totalItems) return <Badge progress="incomplete" status="attention">{`Not fulfilled (0/${totalItems})`}</Badge>;
    return <Badge tone="warning" progress="partiallyComplete">{`Partially fulfilled (${totalItems - toFulfill}/${totalItems})`}</Badge>;
  };

  const rows = Array.isArray(items)
    ? items.map((item) => {
        const serialsToConnect = item.serials_connected_needed - item.serials_connected_qty;

        return [
          <Link removeUnderline url={`/sales/${item.OrderbevestigingID}`}>
            {item.Orderbevestigingnummer}
          </Link>,
          <QuickViewSalesOrderComponent id={item.OrderbevestigingID} />,
          <Badge>{item.Afkorting}</Badge>,
          String(item.Datum).substring(0, 10),
          truncate(item.RefKlant, 20),
          <InlineStack gap="100" blockAlign="center">
            <img
              src={`https://flagcdn.com/w20/${String(item.ISO2).toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${String(item.ISO2).toLowerCase()}.png 2x,https://flagcdn.com/w60/${String(item.ISO2).toLowerCase()}.png 3x`}
              width="18"
              height="13"
              alt={`${String(item.ISO2).toLowerCase()}`}></img>
            {truncate(item.NAAM, 30)}
          </InlineStack>,
          fulfilment_status(item.items_remaining_to_fulfill, item.total_items),
          <Badge tone={serialsToConnect > 0 ? "critical" : ""} progress={serialsToConnect === 0 ? "complete" : item?.serials_connected_qty > 0 ? "partiallyComplete" : "incomplete"}>
            {`Serials (${item?.serials_connected_qty}/${item?.serials_connected_needed})`}
          </Badge>,
          <div>
            {item.Leveringstermijn === "TBD-WEBSHOP"  ? <Badge tone="critical">{String(item.Leveringstermijn).substring(0, 11)}</Badge> : <Badge>{String(item.Leveringstermijn).substring(0, 10)}</Badge>}
            {item.Leveringstermijn === "TBD-WEBSHOP" && <ConfirmDeliveryTerm order_id={item.OrderbevestigingID} callback={fetchItems} />}
            {item.status_fulfilment !== "fulfilled" && item.due_diff ? <Badge>{item.due_diff}d</Badge> : ""}
          </div>,
          // <FulfillerSelectComponent
          //   fetchItems={fetchItems}
          //   user_id={item?.Afkorting}
          //   options={users}
          //   order_id={item.OrderbevestigingID}
          // />,
        ];
      })
    : [];

  if (!loaded) return <PageSpinner />;

  return (
    <Card>
      <div onKeyDown={(e) => handleSaveOnEnter(e, fetchItems)}>
        <Filters
          queryValue={filters.get("q").value}
          filters={addFilters(filters, setFilters)}
          appliedFilters={applyFilters(filters, setFilters)}
          onClearAll={() => removeAllFilters(filters, setFilters)}
          onQueryChange={(e) => handleFilterChange(e, "q", filters.get("q").value, setFilters)}
          onQueryClear={() => handleFilterChange("", "q", filters.get("q").value, setFilters)}
          onQueryBlur={fetchItems}
          autoComplete={false}
          queryPlaceholder="Filter orders"
        />
      </div>
      <Box paddingInlineStart="200">
        <Checkbox checked={showFulfilledOrders} label="Show fulfilled orders" onChange={() => setShowFulfilledOrders(!showFulfilledOrders)} />
      </Box>
      <DataTable
        columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text", "text", "text", "text", "text"]}
        headings={[<b>Number</b>, <b>View</b>, <b>User</b>, <b>Date</b>, <b>Reference</b>, <b>Customer</b>, <b>Fulfilment</b>, <b>Serials</b>, <b>Due date</b>, <b>Delivery term</b>]}
        verticalAlign="middle"
        increasedTableDensity
        rows={rows}
        footerContent={getTableFooter(rows, filters.get("page"), filters.get("limit"), setFilters, "orders")}
      />
    </Card>
  );
}
