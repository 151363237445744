// modal to confirm delivery term from webshop orders

import { Button, Modal, DatePicker, Text } from "@shopify/polaris";
import { useState, useCallback, useMemo } from "react";

export default function ConfirmDeliveryTerm({ order_id, callback }) {
    const [active, setActive] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [{ month, year }, setDate] = useState({ month: selectedDate.getMonth(), year: selectedDate.getFullYear() });

    const handleMonthChange = useCallback((month, year) => setDate({ month, year }),[]);

    const handleDateChange = useCallback((dates) => {
        const date = dates.start || dates;
        if (date instanceof Date) setSelectedDate(date);
    }, []);

    const calculateWeeks = useMemo(() => {
        const today = new Date();
        const diffTime = Math.abs(selectedDate.getTime() - today.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const diffWeeks = Math.ceil(diffDays / 7);
        return diffWeeks;
    }, [selectedDate]);

    const onClose = () => {
        setActive(false);
        resetForm();
    };

    const onConfirm = async () => {
        try {
            const relayServer = process.env.NODE_ENV === 'production' ? 'https://admin.schuilenburg.be' : 'http://localhost:9000';
            const username = 'INTERNAL_AUTOMATION';
            const password = '4A4jQSRhL4diBcEpGPT0';
            const basicAuth = btoa(`${username}:${password}`);

            const dateToSend = selectedDate instanceof Date ? selectedDate : new Date(selectedDate.start);
            dateToSend.setHours(12, 0, 0, 0);

            const response = await fetch(`/api/relay`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    url: `${relayServer}/webshop/confirm-delivery`,
                    method: 'POST',
                    body: { 
                        salesOrderId: order_id, 
                        deliveryDate: dateToSend.toISOString().split('T')[0],
                        integration_id: 1
                    },
                    headers: {
                        'Authorization': `Basic ${basicAuth}`,
                        'Content-Type': 'application/json'
                    },
                }),
            });

            console.log("response mail relay:  ", response);

            setActive(false);
            resetForm();
            callback();
        } catch (error) {
            console.error('Error sending confirmation:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    const resetForm = () => {
        const currentDate = new Date();
        setDate({ month: currentDate.getMonth(), year: currentDate.getFullYear() });
        setSelectedDate(currentDate);
    };

    return <div style={{ marginLeft: "5px", display: "inline-block" }}>
        <Button size="micro" tone="critical" onClick={() => setActive(true)}>Confirm delivery term</Button>
        <Modal
            open={active}
            onClose={onClose}
            title="Confirm delivery term"
            primaryAction={{
                content: 'Confirm',
                onAction: onConfirm,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: onClose,
                },
            ]}
        >
            <Modal.Section>
                <DatePicker
                    month={month}
                    year={year}
                    onChange={handleDateChange}
                    onMonthChange={handleMonthChange}
                    selected={selectedDate}
                    disableDatesAfter={new Date(year, month + 1, 0)}
                />
                <div style={{ marginTop: "16px" }}>
                    <Text variant="bodyMd">
                        Estimated completion time: <strong>{calculateWeeks}</strong> week{calculateWeeks !== 1 ? 's' : ''}
                    </Text>
                </div>
            </Modal.Section>
        </Modal>
    </div>
}